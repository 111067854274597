<template>
  <v-container id="consultationPay">
    <v-row v-if="!loadingConsultation">
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.date') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        {{ appointment.startAt | moment('DD/MM/YYYY HH:mm:ss') }}
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $t('mensajeria.motivo') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        {{ appointment.subject }}
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.precio') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left"> {{ appointment.price }}€ </v-col>
    </v-row>
    <v-row class="consultationDetails">
      <v-col cols="12">
        <h4 class="font-weight-light title-selector">{{ $t('hiring.appointment_proposed_by_professional') }}</h4>
      </v-col>
      <v-col cols="12">
        <v-alert dense outlined type="info" class="mt-4">
          <span v-if="showAfricanInfo">
            {{ $t('agenda.gmtAlertAfrican') }}
          </span>
          <span v-else>
            {{ $t('agenda.gmtAlert') }}
          </span>
        </v-alert>
        <v-alert outlined color="primary" prominent border="left" icon="mdi-information" class="text-left">
          <div class="grey--text font-weight-light">
            {{ $t('hiring.you_have_period_to_confirm') }}
            <br /><br />
            {{ $t('hiring.appreciate_your_understanding') }}
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation />
  </v-container>
</template>

<script>
import { getHeader, getAppointmentsUrl, urlPatient, defaultCountry } from '../../../config/config';

export default {
  name: 'ConsultationPay',
  props: {
    professionalId: {
      type: Number,
      default: 0,
    },
    professionalName: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },
    consultation: {
      type: Object,
      default: () => ({
        isValid: true,
      }),
    },
  },
  data() {
    return {
      appointment: {
        startAt: '2020-01-01T00:00:00.000Z',
      },
      loadingConsultation: false,
      showAfricanInfo: false,
    };
  },
  mounted() {
    this.loadConsultation();
    this.manageInfoMessage();
  },
  methods: {
    loadConsultation() {
      this.consultation.isValid = true;
      this.loadingConsultation = true;
      this.$http
        .get(urlPatient(getAppointmentsUrl, 0, 0) + '/' + this.serviceId + '/info', { headers: getHeader() })
        .then(res => {
          this.appointment = res.body;
          this.$emit('getDataPayConsultation', this.appointment);
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.loadingConsultation = false;
        });
    },
    manageInfoMessage() {
      this.showAfricanInfo = defaultCountry === 'ZA';
    },
  },
};
</script>
<style>
.consultationDetails {
  padding: 0px 12px;
  margin-top: 20px;
}
.pack_text,
.pack_title {
  padding: 0px 5px;
}
.pack_title {
  text-transform: capitalize;
}
</style>
