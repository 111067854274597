const axios = require('axios');

export const put = (endpoint, data, headers) => {
  return axios
    .put(endpoint, data, headers)
    .then(res => {
      return res;
    })
    .catch(error => {
      return {
        status: error.response.status,
        message: error.response.data.error.message,
      };
    });
};

export const post = (endpoint, data, headers) => {
  return axios
    .post(endpoint, data, headers)
    .then(res => {
      return res;
    })
    .catch(error => {
      return {
        status: error.response.status,
        message: error.response.data.error.message,
      };
    });
};
