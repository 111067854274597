<template>
  <v-container>
    <v-row v-if="!loadingSubscription">
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.name') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        {{ subscription.name }}
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.descripcion') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        {{ subscription.description }}
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $t('suscripciones.servicios_incluidos') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        <span v-if="subscription.flatRate">{{ $t('suscripciones.tarifa_plana') }}</span>
        <span v-else>{{ subscription.includedServices }}</span>
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.precio') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left"> {{ subscription.price }} € </v-col>
    </v-row>
    <v-row class="consultationDetails">
      <v-col cols="12">
        <v-alert outlined color="primary" prominent border="left" icon="mdi-information" class="text-left">
          <div class="grey--text font-weight-light">{{ $t('hiring.monthly_fee_will_charged') }}</div>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getHeader, subscriptionsUrl, urlPatient } from '../../../config/config';

export default {
  name: 'SubscriptionHiring',
  props: {
    professionalId: {
      type: Number,
      default: 0,
    },
    professionalName: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },
    consultation: {
      type: Object,
      default: () => ({
        isValid: false,
      }),
    },
  },
  data() {
    return {
      subscription: {},
      loadingSubscription: false,
    };
  },
  mounted() {
    this.loadSubscription();
  },
  methods: {
    loadSubscription() {
      this.loadingSubscription = true;
      this.$http
        .get(urlPatient(subscriptionsUrl, 0, 0) + '/type/' + this.serviceId, { headers: getHeader() })
        .then(res => {
          this.setSubscription(res.body);
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.loadingSubscription = false;
        });
    },

    setSubscription(data) {
      this.subscription = data;
      let includedServices = '';
      this.subscription.services.forEach(service => {
        const nservices = service.uses === -1 ? this.$t('suscripciones.ilimitado') : 'x' + service.uses;
        includedServices += service.name + ' (' + nservices + '). ';
      });
      this.subscription.includedServices = includedServices;
      this.consultation.isValid = true;
    },
  },
};
</script>

<style>
.consultationDetails {
  padding: 0px 12px;
  margin-top: 20px;
}
.pack_text,
.pack_title {
  padding: 0px 5px;
}
.pack_title {
  text-transform: capitalize;
}
</style>
