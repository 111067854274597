<template>
  <v-container id="consultationHiring">
    <template v-if="currentService && Object.keys(currentService).length">
      <v-icon class="d-block">mdi-calendar-text-outline</v-icon>
      <small class="d-block">{{ $t('agenda.disponibilidad') }}</small>
      <h2 class="font-weight-light">{{ currentService.name }}</h2>
      <h3 class="font-weight-light">
        {{ currentService.description }}
      </h3>
    </template>

    <v-alert dense outlined type="info" class="mt-4">
      <span v-if="showAfricanInfo">
        {{ $t('agenda.gmtAlertAfrican') }}
      </span>
      <span v-else>
        {{ $t('agenda.gmtAlert') }}
      </span>
    </v-alert>

    <appointment-selector @slotSelected="slotSelected" @clearSelection="clearSelection" />
    <v-form ref="form" lazy-validation>
      <v-row class="consultationDetails">
        <v-col cols="12">
          <BeneficiariesSelector
            v-if="beneficiaries.length > 0"
            :beneficiaries="beneficiaries"
            @input="setBeneficiary"
          />
        </v-col>
        <v-col v-if="consultation.dateFront !== ''" cols="12">
          <v-alert dense outlined color="primary" border="left" icon="mdi-calendar">
            <h3 class="font-weight-light title-selector">{{ $t('hiring.consultation_details') }}:</h3>
            <h2 class="text-left">{{ consultation.dateFront }}</h2>
            <p class="grey--text font-weight-light title-selector">{{ $t('hiring.professional_will_confirm') }}</p>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="consultation.reason"
            :rules="ruleRequired"
            rows="3"
            outlined
            :label="`${$t('hiring.reason_of_consultation')}*`"
            @change="checkIsValid"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import BeneficiariesSelector from './BenefiariesSelector.vue';
import AppointmentSelector from './AppointmentSelector.vue';
import { defaultCountry } from '@/config/config';

export default {
  name: 'ConsultationHiring',
  components: { BeneficiariesSelector, AppointmentSelector },
  props: {
    professionalId: {
      type: Number,
      default: 0,
    },
    serviceId: {
      type: String,
      default: '',
    },
    services: {
      type: Array,
      default: () => [],
    },
    beneficiaries: {
      type: Array,
      default: () => [],
    },
    consultation: {
      type: Object,
      default: () => ({
        reason: '',
        date: '',
        time: '',
        dateFront: '',
        beneficiaryId: 0,
        isValid: false,
      }),
    },
    validateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ruleRequired: [v => !!v || this.$t('common.obligatorio')],
      showAfricanInfo: false,
    };
  },
  computed: {
    currentService() {
      const services = this.services.reduce((acc, service) => [...acc, service.items].flat(), []);
      return services.find(service => parseInt(service.id) === parseInt(this.serviceId));
    },
  },
  watch: {
    validateForm(status) {
      if (status) {
        this.$refs.form.validate();
        this.checkIsValid();
        if (!this.consultation.isValid) {
          this.$scrollTo('#consultationHiring');
          const msg =
            this.consultation.dateFront === '' ? this.$t('appointments.dateRequired') : this.$t('errors.form_invalid');
          this.$emit('receiveStatusForm', msg);
        }
      }
    },
  },
  mounted() {
    this.manageInfoMessage();
  },
  methods: {
    slotSelected(slot) {
      this.consultation.date = slot.date;
      this.consultation.time = slot.time;
      const dateSlot = slot.date.split('-');
      const monthNames = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ];
      const monthName = this.$t('agenda.' + monthNames[parseInt(dateSlot[1]) - 1]);
      this.consultation.dateFront = dateSlot[2] + ' ' + monthName + ' - ' + slot.time;
      this.checkIsValid();
    },
    clearSelection() {
      if (!this.consultation.dateFront) {
        return;
      }
      this.consultation.date = '';
      this.consultation.time = '';
      this.consultation.dateFront = '';
      this.consultation.isValid = false;
    },
    setBeneficiary(val) {
      this.consultation.beneficiaryId = val;
    },
    checkIsValid() {
      this.consultation.isValid = this.consultation.dateFront !== '' && this.consultation.reason !== '';
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    manageInfoMessage() {
      this.showAfricanInfo = defaultCountry === 'ZA';
    },
  },
};
</script>
<style>
.consultationDetails {
  padding: 0px 12px;
  margin-top: 20px;
}
</style>
