<template>
  <v-container>
    <h2 class="title_payment">{{ $t('hiring.card_payment') }}</h2>
    <form id="payment-form" ref="form">
      <slot name="card-element">
        <div v-show="discount !== 100" id="card-number" :class="rounded ? 'mb-2 input-rounded' : 'mb-2'" />
        <v-row v-show="discount !== 100">
          <v-col>
            <div id="card-expiry" :class="rounded ? 'mb-2 input-rounded' : 'mb-2'" />
          </v-col>
          <v-col>
            <div id="card-cvc" :class="rounded ? 'mb-2 input-rounded' : 'mb-2'" />
          </v-col>
        </v-row>
        <v-text-field
          v-if="coupons.length > 0"
          v-model="coupon"
          dense
          outlined
          :rounded="rounded"
          :placeholder="$t('hiring.have_promotional_code')"
          append-icon="mdi-ticket-percent-outline"
          @keyup="checkPromotionalCode"
        />
      </slot>
      <p v-if="discount > 0" class="grey--text font-weight-light discount-info">
        {{ $t('hiring.discount_applied', { num: discount }) }}
      </p>
      <slot name="card-errors">
        <v-alert v-show="showError" dense outlined type="error" icon="mdi-credit-card-remove-outline">
          <span id="card-errors" role="alert" class="red--text" />
        </v-alert>
      </slot>
      <v-btn
        depressed
        block
        color="primary"
        dark
        :loading="isLoadingHiring || validatingSCA"
        @click.prevent="payService"
      >
        {{ discount !== 100 ? $t('hiring.proceed_to_payment') : $t('hiring.send') }}
        {{ amount !== '' && discount !== 100 ? '(' + amount + '€)' : '' }}
      </v-btn>
    </form>
  </v-container>
</template>

<script>
import {
  getHeader,
  listCouponsUrl,
  checkCouponUrl,
  paymentIntentUrl,
  subscriptionIntentUrl,
  urlPatient,
} from '@/config/config';
import { post, put } from '@/services/axios/methods';

export default {
  name: 'VStripe',

  props: {
    pk: {
      type: String,
      required: true,
    },
    amount: {
      type: String,
      required: true,
    },
    discount: {
      type: Number,
      default: 0,
    },
    patientId: {
      type: Number,
      default: 0,
    },
    professionalId: {
      type: String,
      default: '',
    },
    formIsValid: {
      type: Boolean,
      default: false,
    },
    consultation: {
      type: Object,
      default: () => ({}),
    },
    isLoadingHiring: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      stripe: null,
      elements: null,
      card: null,
      showError: false,
      coupon: '',
      coupons: [],
      paymentIntentId: null,
      clientSecret: null,
      validatingSCA: false,
    };
  },
  computed: {
    style() {
      return {
        base: {
          color: '#32325d',
          fontFamily: '"Roboto", "Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: 'rgba(0, 0, 0, 0.3)',
          },
        },
        invalid: {
          color: '#ff5252',
          iconColor: '#ff5252',
        },
      };
    },
    form() {
      return document.getElementById('payment-form');
    },
  },
  mounted() {
    this.loadCoupons();
    if (window.Stripe) {
      return this.loaded();
    }
    this.loadStripeCheckout(this.pk, 'v3', () => {
      this.loaded();
    });
  },
  methods: {
    loadCoupons() {
      this.$http
        .get(urlPatient(listCouponsUrl, 0, 0, this.patientId) + '/' + this.professionalId, { headers: getHeader() })
        .then(res => {
          this.coupons = res.body;
        })
        .catch(err => {
          this.$log.error(err);
        });
    },

    loadStripeCheckout(pk, version = 'v3', callback) {
      const e = document.createElement('script');
      e.src = `https://js.stripe.com/${version}`;
      e.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(e);
      e.addEventListener('load', callback);
    },

    async payService() {
      this.$emit('checkFormIsValid');
      if (!this.consultation.isValid) {
        return false;
      }
      if (this.discount >= 100) {
        this.$emit('requestService', true);
        return true;
      }
      this.validatingSCA = true;
      const param = {
        amount: this.amount,
        promotionalCode: this.coupon.toUpperCase(),
        professionalId: this.professionalId,
        consultation: this.consultation,
      };

      if (this.consultation.type === 'subscription') {
        this.manageSubscriptionPayment();
      } else {
        const response =
          this.paymentIntentId !== null
            ? await put(urlPatient(paymentIntentUrl + '/' + this.paymentIntentId, 0, 0, this.patientId), param, {
                headers: getHeader(),
              })
            : await post(urlPatient(paymentIntentUrl, 0, 0, this.patientId), param, {
                headers: getHeader(),
              });
        if (response.status === 200) {
          this.paymentIntentId = response.data.id;
          this.clientSecret = response.data.clientSecret;
          this.payManagement();
        } else {
          this.$emit('error', { message: this.$t('errors.try_again') });
          this.validatingSCA = false;
        }
      }
    },

    async manageSubscriptionPayment() {
      try {
        const subscriptionIntentResponse = await this.createSubscriptionIntent();
        if (subscriptionIntentResponse.error) {
          throw subscriptionIntentResponse;
        }
        const subscriptionIntent = subscriptionIntentResponse.body;
        const paymentMethodResponse = await this.createPaymentMethod(this.card, subscriptionIntent);
        if (paymentMethodResponse.error) {
          throw paymentMethodResponse;
        }
        const paymentMethod = paymentMethodResponse.paymentMethod;
        this.$emit('subscription-payment-method-created', paymentMethod);
      } catch (error) {
        this.$emit('error', { message: error.message });
      }
    },

    createSubscriptionIntent() {
      const formData = {
        serviceType: this.consultation.type,
        serviceId: this.consultation.serviceId,
        professionalId: this.professionalId,
      };
      return this.$http.post(urlPatient(subscriptionIntentUrl, 0, 0, this.patientId), formData, {
        headers: getHeader(),
      });
    },

    createPaymentMethod(card, subscriptionIntent) {
      return this.stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: subscriptionIntent.billingName,
          email: subscriptionIntent.billingEmail,
        },
      });
    },

    handleSubscriptionPaymentRequirements(subscriptionPaymentMethod) {
      console.log(subscriptionPaymentMethod);
      console.log('********');
    },

    payManagement() {
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: { card: this.card },
        })
        .then(
          function(result) {
            if (result.error) {
              this.$emit('error', result.error);
            } else if (result.paymentIntent.status === 'succeeded') {
              this.$emit('paymentSucceeded', result.paymentIntent);
            } else {
              this.$emit('error', { message: this.$t('errors.try_again') });
            }
            this.validatingSCA = false;
          }.bind(this)
        );
    },

    loaded() {
      this.stripe = window.Stripe(this.pk);
      this.elements = this.stripe.elements();

      // Card number
      this.card = this.elements.create('cardNumber', {
        placeholder: this.$t('card_number'),
        style: this.style,
      });
      this.card.mount('#card-number');

      // Card expiry
      const exp = this.elements.create('cardExpiry', {
        placeholder: 'MM / YY',
        style: this.style,
      });
      exp.mount('#card-expiry');

      // CVC
      const cvc = this.elements.create('cardCvc', {
        placeholder: 'CVC',
        style: this.style,
      });
      cvc.mount('#card-cvc');

      this.card.addEventListener('change', ({ error }) => {
        const el = document.getElementById('card-errors');
        el.textContent = error ? error.message : '';
        this.showError = error;
      });
    },

    checkPromotionalCode() {
      this.$emit('discount', 0);
      if (this.coupon.length > 3) {
        const coupon = this.coupon.toUpperCase();
        this.coupons.forEach(c => {
          if (coupon.indexOf(c.toUpperCase()) > -1) {
            this.$http
              .get(urlPatient(checkCouponUrl, 0, 0, this.patientId) + '/' + coupon, { headers: getHeader() })
              .then(res => {
                this.$emit('discount', { discount: res.body.discount, coupon: coupon });
                this.showError = false;
              })
              .catch(err => {
                if (err.status !== 400) {
                  this.$log.error(err);
                }
              });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid rgba(66, 71, 112, 0.5);
  border-radius: 4px;
  background-color: white;
}

.StripeElement--focus {
  border: 1px solid #3f51b5;
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #ff5252;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.input-rounded {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #9e9d9d;
  padding-left: 20px;
}
.title_payment {
  text-align: left;
  color: var(--v-primary-base);
  margin-bottom: 10px;
  margin-left: 4px;
}
.discount-info {
  text-align: right;
  margin: -24px 0px 0px 0px;
}
</style>
