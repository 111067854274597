import { render, staticRenderFns } from "./ServiceHiringView.vue?vue&type=template&id=3328831e"
import script from "./ServiceHiringView.vue?vue&type=script&lang=js"
export * from "./ServiceHiringView.vue?vue&type=script&lang=js"
import style0 from "./ServiceHiringView.vue?vue&type=style&index=0&id=3328831e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports