<template>
  <v-container class="hiringService">
    <v-layout v-if="isLoading" fill-height align-center justify-center>
      <v-progress-circular width="2" indeterminate color="primary" />
    </v-layout>
    <v-row v-else class="mb-6" no-gutters>
      <v-col xs="12" sm="4" align="center">
        <professional-information
          :professional="professional"
          :services="services"
          :rounded="rounded"
          :professional-id="professionalId"
        />
      </v-col>
      <v-col xs="12" sm="8" align="center">
        <ConsultationPay
          v-if="isPay"
          :service-id="serviceId"
          :professional-id="professional.id"
          :consultation="consultation"
          @receiveStatusForm="receiveStatusForm"
          @getDataPayConsultation="getDataPayConsultation"
        />
        <ConsultationHiring
          v-else-if="serviceType === 'appointment'"
          :services="services"
          :service-id="serviceId"
          :beneficiaries="beneficiaries"
          :professional-id="professional.id"
          :consultation="consultation"
          :validate-form="validateForm"
          @receiveStatusForm="receiveStatusForm"
        />

        <MessageHiring
          v-if="serviceType === 'message'"
          :beneficiaries="beneficiaries"
          :professional-name="professional.name"
          :consultation="consultation"
          :validate-form="validateForm"
          @receiveStatusForm="receiveStatusForm"
        />
        <PackHiring
          v-if="serviceType === 'pack'"
          :service-id="serviceId"
          :beneficiaries="beneficiaries"
          :professional-id="professional.id"
          :validate-form="validateForm"
          :professional-name="professional.name"
          :consultation="consultation"
          @receiveStatusForm="receiveStatusForm"
        />
        <SubscriptionHiring
          v-if="serviceType === 'subscription'"
          :service-id="serviceId"
          :professional-id="professional.id"
          :consultation="consultation"
          :professional-name="professional.name"
        />
        <v-alert
          v-model="activeSubscription"
          color="success"
          class="success--text"
          border="left"
          elevation="1"
          colored-border
          depressed
          icon="mdi-check-decagram"
        >
          <span class="alert-active-subscription">{{ $t('hiring.free_with_your_subscription') }}</span>
        </v-alert>

        <v-alert
          v-if="serviceType === 'subscription'"
          border="left"
          colored-border
          type="warning"
          elevation="2"
          class="mx-6 text-subtitle-1 font-weight-medium"
        >
          {{ $t('common.subscriptions_temporarily_disabled') }}
        </v-alert>

        <v-alert
          v-if="!isLoading && !canPayConsultation"
          border="left"
          colored-border
          elevation="1"
          color="error"
          class="mx-6 text-subtitle-1 font-weight-medium"
        >
          <v-row align="center" class="mx-1 flex-nowrap pa-3">
            <v-avatar color="error" size="40" class="mr-4">
              <v-icon dark size="24">mdi-alert-circle</v-icon>
            </v-avatar>
            <div>{{ $t('videoconsultations.paymentPatientNotCorresponding') }}</div>
          </v-row>
        </v-alert>

        <template v-if="serviceType !== 'subscription' && canPayConsultation">
          <VStripe
            v-if="showStripeCheckout"
            ref="stripeRef"
            :pk="publishableKey"
            :is-loading-hiring="isLoadingHiring"
            :amount="amount"
            :discount="discount"
            :professional-id="professionalId"
            :patient-id="patientId"
            :consultation="consultation"
            @error="stripeError"
            @discount="discountApplied"
            @requestService="requestService"
            @checkFormIsValid="checkFormIsValid"
            @paymentSucceeded="paymentSucceeded"
            @subscription-payment-method-created="subscriptionPaymentMethodCreated"
          />
          <div v-else :style="styleServiceHiring">
            <v-btn depressed color="primary" dark :loading="isLoadingHiring" @click="requestService">{{
              $t('hiring.send')
            }}</v-btn>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import environment from '@/environment';
import { beneficiariesUrl, professionalsUrl, getHeader, urlPatient, subscriptionsUrl } from '../../../config/config';
import VStripe from '../../../modules/VuetifyStripe/components/VStripe.vue';
import ConsultationHiring from './ConsultationHiring.vue';
import ConsultationPay from './ConsultationPay.vue';
import MessageHiring from './MessageHiring.vue';
import PackHiring from './PackHiring.vue';
import SubscriptionHiring from './SubscriptionHiring.vue';
import ProfessionalInformation from '@/components/patients/hiring/ProfessionalInformation';

export default {
  name: 'ServiceHiring',
  components: {
    ProfessionalInformation,
    ConsultationPay,
    SubscriptionHiring,
    PackHiring,
    MessageHiring,
    ConsultationHiring,
    VStripe,
  },
  props: {
    serviceType: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },
    professionalId: {
      type: String,
      default: '',
    },
    isPay: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      serviceName: '',
      typeId: 0,
      patientId: 0,
      isLoading: false,
      isLoadingHiring: false,
      professional: { id: 0, name: '', image: '' },
      beneficiaries: [],
      services: [
        { icon: 'mdi-forum-outline', name: 'message', items: [], text: vm.$t('common.messaging') },
        { icon: 'mdi-video-wireless-outline', name: 'appointment', items: [], text: vm.$tc('appointments.name', 2) },
        { icon: 'mdi-plus-box-outline', name: 'others', items: [], text: vm.$t('service.more_services') },
      ],
      styleServiceHiring: 'max-width: 650px',
      publishableKey: environment.publish_key_stripe,
      amount: '',
      amountService: '',
      discount: 0,
      coupon: '',
      externalPaymentIntentId: null,
      charge: {},
      showStripeCheckout: false,
      consultation: {
        reason: '',
        date: '',
        time: '',
        dateFront: '',
        beneficiaryId: 0,
        isValid: false,
        type: '',
        serviceId: 0,
      },
      activeSubscription: false,
      validateForm: false,
      redirectTo: '',
      subscriptionPaymentMethod: null,
    };
  },
  computed: {
    ...mapState({
      appLogoPrimary: state => state.app.images.logoPrimary,
    }),
    canPayConsultation() {
      if (this.serviceType !== 'appointment') {
        return true;
      }
      if (!this.authUser) {
        return true;
      }

      const loggedUserId = this.authUser?.patient?.id || this.authUser?.id;
      if (loggedUserId === this.patientId) {
        return true;
      }
      // Check if patient is a beneficiary of the logged user.
      if (this.beneficiaries) {
        return this.beneficiaries.some(patient => patient.id === loggedUserId);
      }

      return false;
    },
  },
  mounted() {
    this.serviceName = this.$tc(this.serviceType + 's.name', 1);
    this.consultation.type = this.serviceType;
    this.consultation.serviceId = this.serviceId;
    this.loadData(professionalsUrl + '/' + this.professionalId, 'professional');
    this.loadData(professionalsUrl + '/' + this.professionalId + '/services?limit=200', 'services');
    if (!this.isPay || (this.authUser && this.serviceType === 'appointment')) {
      this.loadData(urlPatient(beneficiariesUrl, 100, 1), 'beneficiaries');
    }
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    checkStatusSubscription() {
      this.isLoading = true;
      this.$http
        .get(urlPatient(beneficiariesUrl, 100, 1), { headers: getHeader() })
        .then(res => {
          this.beneficiaries = this.setBeneficiaries(res.body.data);
        })
        .catch(err => {
          this.catchError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    loadData(url, type) {
      this.isLoading = true;
      this.$http
        .get(url, { headers: getHeader() })
        .then(res => {
          if (type === 'beneficiaries') {
            this.setBeneficiaries(res.body.data);
          } else if (type === 'services') {
            this.setServices(res.body.data);
          } else if (type === 'professional') {
            this.professional = res.body;
          } else if (type === 'statusSubscription' && res.body.active) {
            this.showStripeCheckout = false;
            this.ammount = 0;
            this.activeSubscription = true;
          }
        })
        .catch(err => {
          this.catchError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setServices(data) {
      const messageKey = 'messaging';
      const appointmentKey = 'videoconsultation';
      const subscriptionKey = 'subscription';
      const packKey = 'pack';
      data.forEach(serv => {
        serv.serviceName = serv.type === messageKey ? this.$t('common.messaging') : serv.name;
        if (serv.type === messageKey) {
          serv.serviceIcon = 'mdi-forum-outline';
          serv.typeName = 'message';
          serv.typeId = 1;
          this.services[0].items.push(serv);
        } else if (serv.type === appointmentKey) {
          serv.serviceIcon = 'mdi-video-wireless-outline';
          serv.typeName = 'appointment';
          serv.typeId = 2;
          this.services[1].items.push(serv);
        } else if (serv.type === subscriptionKey || serv.type === packKey) {
          serv.serviceIcon = serv.type === subscriptionKey ? 'mdi-autorenew' : 'mdi-package-variant-closed';
          serv.typeName = serv.type === subscriptionKey ? 'subscription' : 'pack';
          serv.typeId = serv.type === subscriptionKey ? 3 : 4;
          this.services[2].items.push(serv);
        }
        if (this.serviceType === serv.typeName && parseInt(this.serviceId) === serv.id) {
          this.typeId = this.setTypeId(serv.type);
          this.amount = serv.price.toString();
          this.amountService = serv.price;
          this.showStripeCheckout = serv.price > 0;
          if (!this.isPay && (serv.type === messageKey || serv.type === appointmentKey)) {
            this.loadData(
              urlPatient(subscriptionsUrl, 0, 0) + '/service?serviceId=' + serv.id + '&typeId=' + this.typeId,
              'statusSubscription'
            );
          }
        }
      });
    },

    setTypeId(type) {
      const types = {
        messaging: 1,
        videoconsultation: 2,
        subscription: 3,
        pack: 4,
      };
      return types[type];
    },

    setBeneficiaries(data) {
      data.forEach(function(beneficiary) {
        beneficiary.name = beneficiary.name + ' ' + beneficiary.surname;
      });
      const patientId = this.authUser && typeof this.authUser.patient.id !== 'undefined' ? this.authUser.patient.id : 0;
      if (data.length > 0) {
        if (patientId > 0) {
          data.unshift({
            id: patientId,
            name: this.authUser.patient.nombre + ' ' + this.authUser.patient.apellidos,
          });
        }
      }
      this.consultation.beneficiaryId = patientId;
      this.patientId = patientId;
      this.beneficiaries = data;
    },

    setDataRequest() {
      const param = {
        professionalId: this.professionalId,
        typeId: this.serviceId,
        price: parseFloat(this.amount),
        notifySms: 0,
        beneficiaryId: this.consultation.beneficiaryId,
        coupon: this.coupon,
        externalPaymentIntentId: this.externalPaymentIntentId,
        subscriptionPaymentMethodId: this.subscriptionPaymentMethodId,
      };
      if (this.isPay) {
        this.redirectTo = '/myvideoconsultations';
      } else if (this.serviceType === 'appointment') {
        this.redirectTo = '/myvideoconsultations';
        param.subject = this.consultation.reason;
        param.startAt = this.consultation.date + ' ' + this.consultation.time;
      } else if (this.serviceType === 'message') {
        this.redirectTo = '/mymessages';
        param.subject = this.consultation.reason.substr(0, 20) + '...';
        param.message = this.consultation.reason;
        param.attachedFile =
          this.consultation.document.file !== ''
            ? { name: this.consultation.document.file.name, content: this.consultation.document.base64 }
            : null;
      } else if (this.serviceType === 'pack') {
        this.redirectTo = '/mysubscriptions';
        param.observations = this.consultation.reason;
      } else if (this.serviceType === 'subscription') {
        this.redirectTo = '/mysubscriptions';
      }
      return param;
    },

    stripeError(err) {
      this.$scrollTo('#form-creditcard');
      this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: err.message });
    },

    paymentSucceeded(payment) {
      this.externalPaymentIntentId = payment.id;
      this.requestService();
    },

    subscriptionPaymentMethodCreated(paymentMethod) {
      this.subscriptionPaymentMethodId = paymentMethod.id;
      this.requestService();
    },

    discountApplied(val) {
      if (parseInt(val.discount) > 0) {
        this.discount = val.discount;
        const new_amount = this.amountService - (this.amountService * parseInt(this.discount)) / 100;
        this.amount = new_amount.toString();
        this.coupon = val.coupon;
      } else {
        this.amount = this.amountService.toString();
        this.discount = 0;
        this.coupon = '';
      }
    },

    checkFormIsValid() {
      this.validateForm = true;
      return this.consultation.isValid;
    },

    requestService() {
      if (!this.checkFormIsValid()) {
        return false;
      }
      this.isLoadingHiring = true;
      let endpoint = '/' + this.serviceType + 's';
      if (this.isPay) {
        endpoint += '/' + this.serviceId + '/pay';
      }
      this.$http
        .post(urlPatient(endpoint, 0, 0), this.setDataRequest(), { headers: getHeader() })
        .then(() => {
          if (this.serviceType === 'subscription') {
            this.$refs.stripeRef.handleSubscriptionPaymentRequirements(this.subscriptionPaymentMethod);
          } else {
            this.saveDone();
          }
        })
        .catch(err => {
          this.catchError(err);
        })
        .finally(() => {
          this.isLoadingHiring = false;
        });
    },

    saveDone() {
      const msg = {
        message: this.$t('success.serviceHiring', { name: this.$t('service.name', { name: this.serviceName }) }),
        icon: 'mdi-check',
        color: 'success',
      };
      window.localStorage.setItem('msg_ehealth', JSON.stringify(msg));
      this.$router.push(this.redirectTo);
    },

    receiveStatusForm(msg) {
      if (msg !== '') {
        this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
        this.validateForm = false;
      }
    },

    getDataPayConsultation(consultation) {
      this.patientId = consultation.patientId;
      this.typeId = consultation.typeId;
      this.amount = consultation.price.toString();
      this.amountService = consultation.price;
      this.showStripeCheckout = consultation.price > 0;
      if (consultation.paid) {
        this.$router.push('/login');
      }
    },

    catchError(err) {
      this.$log.error(err);
      let msg = 'Ocurrió un error!';
      if (err && err.response && err.response.data) {
        msg = err.response.data.detail;
      }
      this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
    },
  },
};
</script>
<style>
.alert-active-subscription {
  margin: 0px -10px;
}
.hiringService {
  max-width: 1128px;
}
</style>
