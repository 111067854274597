<template>
  <v-container id="packHiring">
    <v-row v-if="!loadingPack">
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.name') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        {{ pack.name }}
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.descripcion') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        {{ pack.description }}
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $tc('suscripciones.sesiones', 2) }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left">
        {{ pack.sessions }}
      </v-col>
      <v-col cols="4" class="pack_title text-right"> {{ $t('common.precio') }}: </v-col>
      <v-col cols="8" class="pack_text primary--text text-left"> {{ pack.price }}€ </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-row class="consultationDetails">
        <v-col cols="12">
          <BeneficiariesSelector
            v-if="beneficiaries.length > 0"
            :beneficiaries="beneficiaries"
            @input="setBeneficiary"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="consultation.reason"
            :rules="ruleRequired"
            outlined
            rounded
            :label="$t('hiring.note_to_professional')"
            @change="checkIsValid"
          />
        </v-col>
        <v-col cols="12">
          <v-alert outlined color="primary" prominent border="left" icon="mdi-information" class="text-left">
            <div class="grey--text font-weight-light">{{ $t('hiring.professional_will_approve_pack') }}</div>
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { getHeader, getPacksUrl, urlPatient } from '../../../config/config';
import BeneficiariesSelector from './BenefiariesSelector.vue';

export default {
  name: 'PackHiring',
  components: { BeneficiariesSelector },
  props: {
    professionalId: {
      type: Number,
      default: 0,
    },
    professionalName: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },
    beneficiaries: {
      type: Array,
      default: () => [],
    },
    consultation: {
      type: Object,
      default: () => ({
        reason: '',
        beneficiaryId: 0,
        isValid: false,
      }),
    },
    validateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pack: {},
      loadingPack: false,
      ruleRequired: [v => !!v || this.$t('common.obligatorio')],
    };
  },
  watch: {
    validateForm(status) {
      if (status) {
        this.$refs.form.validate();
        this.checkIsValid();
        if (!this.consultation.isValid) {
          this.$scrollTo('#packHiring');
          this.$emit('receiveStatusForm', this.$t('errors.form_invalid'));
        }
      }
    },
  },
  mounted() {
    this.loadPack();
  },
  methods: {
    loadPack() {
      this.loadingPack = true;
      this.$http
        .get(urlPatient(getPacksUrl, 0, 0) + '/type/' + this.serviceId, { headers: getHeader() })
        .then(res => {
          this.pack = res.body;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.loadingPack = false;
        });
    },
    setBeneficiary(val) {
      this.consultation.beneficiaryId = val;
    },
    checkIsValid() {
      this.consultation.isValid = this.consultation.reason !== '';
    },
  },
};
</script>
<style>
.consultationDetails {
  padding: 0px 12px;
  margin-top: 20px;
}
.pack_text,
.pack_title {
  padding: 0px 5px;
}
.pack_title {
  text-transform: capitalize;
}
</style>
