<template>
  <v-container class="beneficiariesContainer">
    <h4 class="font-weight-light title-selector">{{ $t('hiring.select_receiver') }}:</h4>
    <v-select
      v-model="value"
      outlined
      filled
      auto
      dense
      :rounded="rounded"
      :items="beneficiaries"
      item-text="name"
      item-value="id"
    />
  </v-container>
</template>

<script>
export default {
  name: 'BeneficiariesSelector',
  props: {
    beneficiaries: {
      type: Array,
      default: () => [],
    },
    beneficiary: {
      type: Number,
      default: 0,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 0,
    };
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
  mounted() {
    this.value = this.beneficiaries.length > 0 ? this.beneficiaries[0].id : 0;
  },
};
</script>
<style>
.title-selector {
  text-align: left;
  font-size: 15px;
}
.beneficiariesContainer {
  padding: 0px !important;
}
</style>
