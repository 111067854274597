<template>
  <section class="appointment-selector">
    <!-- controls -->
    <v-sheet tile height="54" class="d-flex">
      <v-toolbar-title v-if="$refs.calendar"> {{ $refs.calendar.title }} </v-toolbar-title>
      <v-spacer />
      <v-btn icon class="ma-2" @click="handlePrev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon class="ma-2" @click="handleNext">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>

    <!-- calendar -->
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :event-height="24"
        :weekdays="weekdays"
        type="week"
        :events="calendarFormattedSlots"
        event-overlap-mode="stack"
        :event-overlap-threshold="30"
        locale="es"
        :interval-height="48"
        @click:event="handleEventClick"
      >
        <template v-slot:day-body="{ date, week }">
          <div class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: nowY }" />
        </template>
        <template v-slot:event="{ eventParsed }">
          <div class="event-item-slot" :class="{ [disabledEventSlotClass]: eventParsed.input.disabled }">
            <p v-if="eventParsed.input.disabled" class="mb-0">{{ eventParsed.input.name }}.</p>
            <small v-if="!eventParsed.input.dayOff"> {{ eventParsed.start.time }} / {{ eventParsed.end.time }} </small>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </section>
</template>

<script>
import { getAvailability, getHeader, urlPatient } from '@/config/config';

export default {
  name: 'AppointmentSelector',

  data() {
    return {
      value: '',
      ready: false,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      days: [],
      offSlots: [],
      startDate: new Date(),
      endDate: new Date(),
      format: 'YYYY-MM-DD HH:mm',
      dayFormat: 'YYYY-MM-DD',
      activeClass: 'green',
      disabledEventClass: 'event-disabled',
      disabledEventSlotClass: 'slot-disabled',
      eventDomElement: 'v-event-timed white--text',
      activeColor: '#2677be',
      disabledColor: '#BDBDBD',
    };
  },

  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },

    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },

    openSlots() {
      return this.days
        .map(day => {
          return day.map(slot => {
            const start = this.$moment(`${slot.date} ${slot.time}`).format(this.format);
            const end = this.$moment(`${slot.date} ${slot.time}`)
              .add(slot.duration, 'minutes')
              .format(this.format);

            return {
              start,
              end,
              name: this.$t('common.cita'),
              color: this.activeColor,
            };
          });
        })
        .filter(e => e.length)
        .flat();
    },

    closedSlots() {
      return this.offSlots
        .map(slot => {
          const start = this.$moment(`${slot.date} ${slot.start}`).format(this.format);
          const end = this.$moment(`${slot.date} ${slot.dayOff ? '23:59' : slot.end}`).format(this.format);

          return {
            date: slot.date,
            start,
            end,
            name: this.$t('calendario.nodisponible'),
            color: this.disabledColor,
            disabled: true,
            dayOff: slot.dayOff,
          };
        })
        .filter(slot => {
          const today = this.$moment().format(this.dayFormat);
          const isPast = this.$moment(slot.date).isSameOrAfter(today);
          return isPast;
        });
    },

    calendarFormattedSlots() {
      return this.openSlots.concat(this.closedSlots);
    },
  },

  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
    this.setWeekBounds();
    this.getSlots();
  },

  methods: {
    showToast() {
      this.$toast(this.$t('hiring.apiError'), {
        x: 'center',
        y: 'top',
        color: 'primary',
      });
    },

    async getSlots() {
      if (!this.startDate || !this.endDate) {
        // invalid dates, abort
        this.showToast();
        return;
      }

      const params = {
        professionalId: this.$route.params.professionalId,
        startDate: this.startDate,
        endDate: this.endDate,
      };

      try {
        const {
          data: { slots, slotsBusy },
        } =
          (await this.$http.post(urlPatient(getAvailability, null, null) + '/' + this.$route.params.serviceId, params, {
            headers: getHeader(),
          })) || {};

        this.days = slots;
        this.formatOffSlots(slotsBusy);
        this.setDisabledSlotsInDom();
      } catch (e) {
        this.showToast();
      }
    },

    setWeekBounds({ prev, next } = {}) {
      this.startDate = this.$moment(this.startDate)
        .startOf('isoWeek')
        .subtract(prev ? 7 : 0, 'days')
        .add(next ? 7 : 0, 'days')
        .format(this.format);

      this.endDate = this.$moment(this.endDate)
        .endOf('isoWeek')
        .subtract(prev ? 7 : 0, 'days')
        .add(next ? 7 : 0, 'days')
        .format(this.format);
    },

    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },

    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    handlePrev() {
      this.$refs.calendar.prev();
      this.setWeekBounds({ prev: true });
      this.getSlots();
      this.$emit('clearSelection');
    },

    handleNext() {
      this.$refs.calendar.next();
      this.setWeekBounds({ next: true });
      this.getSlots();
      this.$emit('clearSelection');
    },

    handleEventClick(rawEvent) {
      const {
        event: { start },
        nativeEvent: { target },
      } = rawEvent;

      this.handleEventClasses(target);

      const raw = start.split(' ');
      const eventData = {
        date: raw[0],
        time: raw[1],
      };

      this.$emit('slotSelected', eventData);
    },

    handleEventClasses({ parentNode: { classList: elementClasses } }) {
      if (this.eventDomElement !== elementClasses.value) {
        return;
      }

      const els = document.getElementsByClassName(this.eventDomElement);
      Array.from(els).forEach(el => el.classList.remove(this.activeClass));
      elementClasses.toggle(this.activeClass);
    },

    formatOffSlots(items) {
      this.offSlots = [];
      for (const slot in items) {
        this.offSlots.push(...items[slot]);
      }
    },

    setDisabledSlotsInDom() {
      this.$nextTick(() => {
        const events = this.$refs.calendar?.$el.getElementsByClassName(this.eventDomElement);
        if (!events) {
          return;
        }

        Array.from(events).forEach(el => {
          const disabled = el.getElementsByClassName(this.disabledEventSlotClass);
          if (!disabled.length) {
            return;
          }
          el.classList.add(this.disabledEventClass);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.v-event-timed-container,
::v-deep .v-calendar .v-event-timed-container {
  margin-right: 0;
}

::v-deep .v-calendar-daily__day.v-past {
  background-color: #f7f7f7;
}

::v-deep .theme--light.v-calendar-events .v-event-timed.white--text {
  & > div {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    strong {
      pointer-events: none;
    }
  }
}

.appointment-selector {
  .v-toolbar__title {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
}

::v-deep .event-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

::v-deep .event-item-slot * {
  pointer-events: none;
}
</style>
