<template>
  <v-container id="messageHiring">
    <h3 class="font-weight-light">
      <v-icon>mdi-message-text-outline</v-icon> {{ $t('messages.write_your_consultation', { name: professionalName }) }}
    </h3>
    <v-form ref="form" lazy-validation>
      <v-row class="consultationDetails">
        <v-col cols="12">
          <BeneficiariesSelector
            v-if="beneficiaries.length > 0"
            :beneficiaries="beneficiaries"
            @input="setBeneficiary"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="consultation.reason"
            :rules="ruleRequired"
            outlined
            :label="$t('hiring.reason_of_consultation')"
            @change="checkIsValid"
          />
        </v-col>
        <v-col cols="12">
          <v-alert color="grey-darken-1" outlined>
            <h4 class="font-weight-light title-selector">{{ $t('hiring.add_document') }}:</h4>
            <d-upload-file v-if="!document.selected" :document="document" :dense="true" :convert-auto-base64="true" />
            <div v-else>
              <div class="mb-5">
                <v-icon size="140" color="grey lighten-2">{{ document.typeIcon }}</v-icon>
              </div>
              <h2>{{ $t('hiring.document_added') }}</h2>
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import DUploadFile from '../../DUploadFile.vue';
import BeneficiariesSelector from './BenefiariesSelector.vue';

export default {
  name: 'MessageHiring',
  components: { DUploadFile, BeneficiariesSelector },
  props: {
    professionalName: {
      type: String,
      default: '',
    },
    beneficiaries: {
      type: Array,
      default: () => [],
    },
    consultation: {
      type: Object,
      default: () => ({
        reason: '',
        beneficiaryId: 0,
        isValid: false,
      }),
    },
    validateForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      document: {
        selected: false,
        uploaded: false,
        selecting: false,
        file: '',
        base64: '',
        typeIcon: 'mdi-text-box-check-outline',
        sizeMax: 20,
        professional: 0,
      },
      ruleRequired: [v => !!v || this.$t('common.obligatorio')],
    };
  },
  watch: {
    validateForm(status) {
      if (status) {
        this.$refs.form.validate();
        this.checkIsValid();
        if (!this.consultation.isValid) {
          this.$scrollTo('#messageHiring');
          this.$emit('receiveStatusForm', this.$t('errors.form_invalid'));
        }
      }
    },
  },
  mounted() {
    this.consultation.document = this.document;
  },
  methods: {
    checkIsValid() {
      this.consultation.isValid =
        this.consultation.reason !== '' &&
        (this.document.file === '' || (this.document.file !== '' && this.document.base64 !== ''));
    },
    setBeneficiary(val) {
      this.consultation.beneficiaryId = val;
    },
  },
};
</script>
<style>
.consultationDetails {
  padding: 0px 12px;
  margin-top: 20px;
}
</style>
