<template>
  <v-container>
    <v-row align="center" justify="center" class="pt-5">
      <v-card elevation="0" width="1228" class="mb-5">
        <ServiceHiring
          :service-type="serviceType"
          :service-id="serviceId"
          :professional-id="professionalId"
          :is-pay="isPay"
        />
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { displayNameEnv } from '../../config/config';
import ServiceHiring from '../../components/patients/hiring/ServiceHiring.vue';

export default {
  components: { ServiceHiring },
  title: displayNameEnv + ' - ' + vm.$t('hiring.service'),
  data() {
    return {
      serviceType: vm.$route.params.service,
      serviceId: vm.$route.params.serviceId,
      professionalId: vm.$route.params.professionalId,
      isPay: vm.$route.name === 'payAppointment',
    };
  },
};
</script>
<style>
.details {
  padding: 50px;
}
.text-field-date {
  width: 98%;
  margin-left: 4px !important;
}
</style>
