<template>
  <v-container class="professionalInformation">
    <d-avatar :text="professional.name" size="185" rounded :two-letter="true" :src="professional.image" />
    <div class="headline mb-1">{{ professional.name }}</div>
    <div v-if="professional && professional.collegiateNumber" class="mt-2 collegiate">
      <small>{{ $t('user.num_colegiado') }}: {{ professional.collegiateNumber }}</small>
    </div>
    <div class="mt-4 mb-6">
      <v-chip
        v-for="specialty in professional.specialties"
        :key="specialty"
        class="grey--text"
        color="grey lighten-4"
        >{{ specialty }}</v-chip
      >
    </div>
    <v-row class="mt-8">
      <div v-for="(serv, index) in services" :key="'test-' + index" class="div-services mb-4">
        &nbsp;
        <v-col xs="12" class="col-services">
          <v-btn
            v-if="serv.items.length === 1 && serv.name !== 'others'"
            class="ma-2 btn-details"
            color="primary"
            :rounded="rounded"
            depressed
            block
            :to="'/hiringService/' + professionalId + '/' + serv.name + '/' + serv.items[0].id"
          >
            <v-icon left>{{ serv.icon }}</v-icon>
            {{ serv.text }} -
            {{ parseFloat(serv.items[0].price) > 0 ? serv.items[0].price + '€' : $t('common.gratuita') }}
          </v-btn>
          <v-menu v-else-if="serv.items.length > 0" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2 btn-details"
                :rounded="rounded"
                depressed
                block
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>{{ serv.icon }}</v-icon>
                {{ serv.text }}
                <v-icon right dark>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="item in serv.items"
                :key="item.typeName + '_' + item.id"
                :to="'/hiringService/' + professionalId + '/' + item.typeName + '/' + item.id"
              >
                <v-list-item-icon>
                  <v-icon>{{ setIcon(item) }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.serviceName }}
                    <template v-if="authUser && authUser.payment_module">
                      - {{ parseFloat(item.price) > 0 ? item.price + '€' : $t('common.gratuita') }}
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle class="professionalInformation__service-description">
                    {{ item.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import DAvatar from '../../DAvatar.vue';
export default {
  name: 'ProfessionalInformation',
  components: { DAvatar },
  props: {
    professional: {
      type: Object,
      default: () => ({}),
    },
    services: {
      type: Array,
      default: () => [],
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    professionalId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  computed: {
    setIcon() {
      return item => (item.faceToFace ? 'mdi-account' : item.serviceIcon);
    },
  },
};
</script>
<style lang="scss" scoped>
.professionalInformation {
  padding: 0px;

  &__service-description {
    width: rem(280);
    max-width: 100%;
  }
}

.div-services {
  width: 100%;
  margin: 0px 24px 0px 0px;
}

.col-services {
  margin: 0px 16px -8px 8px;
  padding: 0px 20px;
}

.btn-details {
  padding: 0px !important;
}
</style>
